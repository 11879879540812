import React, { useState, useEffect, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import { getStoreAuthToken } from '../../context/auth'

const postObj = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  },
  body: {}
}

export async function dailyWater (callback, params) {
  params.token = getStoreAuthToken()
  const newPostObj = { ...postObj, body: JSON.stringify(params) }
  const response = await fetch(
    'https://uobtsk.gpsolutions.sg/api/water/dailyWater',
    newPostObj
  ).catch(function (error) {
    callback({ return: 1000, msg: 'Network error' })
  })
  try {
    const json = await response.json()
    callback(json)
  } catch (e) {
    callback({ return: 1000, msg: 'Network error' })
  }
}

export async function dailySegmentWater (callback, params) {
  params.token = getStoreAuthToken()
  const newPostObj = { ...postObj, body: JSON.stringify(params) }
  const response = await fetch(
    'https://uobtsk.gpsolutions.sg/api/water/dailySegmentWater',
    newPostObj
  ).catch(function (error) {
    callback({ return: 1000, msg: 'Network error' })
  })
  try {
    const json = await response.json()
    callback(json)
  } catch (e) {
    callback({ return: 1000, msg: 'Network error' })
  }
}
